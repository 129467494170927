/* fonts */
@font-face {
  font-family: Mont;
  font-display: auto;
  src: url("../fonts/Mont-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Mont;
  font-display: swap;

  font-weight: 600;
  src: url("../fonts/Mont-SemiBold.ttf");
}
@font-face {
  font-family: Mont;
  font-display: swap;

  font-weight: 700;
  src: url("../fonts/Mont-Bold.ttf");
}
@font-face {
  font-family: Mont;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/Mont-Heavy.ttf");
}

@font-face {
  font-family: Noto;
  font-display: auto;
  src: url("../fonts/NotoSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Noto;
  font-display: swap;

  font-weight: 600;
  src: url("../fonts/NotoSans-SemiBold.ttf");
}
@font-face {
  font-family: Noto;
  font-display: swap;

  font-weight: 700;
  src: url("../fonts/NotoSans-Bold.ttf");
}
@font-face {
  font-family: Noto;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/NotoSans-Black.ttf");
}

/* video playlist */

.title {
  width: 100%;
  max-width: 854px;
  margin: 0 auto;
}

.caption {
  width: 100%;
  max-width: 854px;
  margin: 0 auto;
  padding: 20px 0;
}

.vid-main-wrapper {
  width: 100%;
  max-width: 1100px;

  margin: 0 auto;
}

.vid-container {
  /* position: relative; */
  padding-bottom: 52%;
  padding-top: 30px;
  height: 0;
  width: 70%;
  float: left;
}

.vid-container iframe,
.vid-container object,
.vid-container embed {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  min-height: 360px;
  background-color: black;
}

.vid-list-container {
  height: 360px;
  overflow: scroll;
  /* float: right; */
  border-bottom: 2px solid #38b6ff;
}

.vid-list-container:hover,
.vid-list-container:focus {
  overflow-y: auto;
}

ol#vid-list {
  margin: 0;
  padding: 0;
  background: #222;
}

ol#vid-list li {
  list-style: none;
}

ol#vid-list li a {
  text-decoration: none;
  background-color: #fff;
  height: 55px;
  display: block;
  padding: 10px;
}

ol#vid-list li a:hover {
  background-color: #38b6ff;
}

ol#vid-list li a:hover .desc {
  color: white;
}

.vid-thumb {
  float: left;
  margin-right: 8px;
}

.active-vid {
  background: #3a3a3a;
}

#vid-list .desc {
  color: #38b6ff;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}

@media (max-width: 624px) {
}

/* accordion */

button.accordion {
  width: 100%;
  font-weight: 700;
  /* background-color: white; */
  border: none;
  outline: none;
  text-align: left;
  padding: 15px 20px;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s linear;
  justify-content: space-between;
}

button.accordion:hover {
  color: #38b6ff;
}

.accordion-content {
  /* background-color: white;
     border-left: 1px solid whitesmoke;
     border-right: 1px solid whitesmoke; */
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  font-weight: 600;
}

/* hide number input arrow */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* heading hightlight */

.heading-bg {
  position: relative;
}

.heading-bg:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 40%;
  z-index: 1; /* Place the pseudo element right under the content */
  top: 35px;
  left: 0;
  /* background: linear-gradient(to top, #c3e9ff 50%, transparent 50%); */
  background: rgba(56, 182, 255, 0.3) !important;
  animation-name: highlight;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-direction: ease-in-out;
}

.bg-remove::after {
  display: none !important;
}

@keyframes highlight {
  0% {
    width: 0;
    opacity: 0;
  }

  50% {
    width: 100%;
    opacity: 1;
  }
}

@media only screen and (max-width: 1024px) {
  .heading-bg:after {
    height: 50%;
    top: 20px;
  }
  .prev-btn {
    left: 0rem !important;
    padding: 5px !important;
    font-size: 15px !important;
  }
  .next-btn {
    right: 0rem !important;
    padding: 5px !important;
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
}
